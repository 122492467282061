import { Controller } from "stimulus";
import { metaCsrfToken } from "../csrf";

// Heroicons Trash Icon
const TRASHCAN = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z" fill="#4A5568"/>
</svg>
`;

export class Recipients extends Controller {
  static targets = ["bulk", "submit", "list", "n", "del", "next", "skip"];
  static values = { url: String, bulkUrl: String, userEmail: String, initialEmail: String, maxEmails: Number };

  bulkTarget: HTMLTextAreaElement;
  submitTarget: HTMLButtonElement;
  nextTarget: HTMLButtonElement;
  skipTarget: HTMLAnchorElement;
  listTarget: HTMLDivElement;
  nTarget: HTMLParagraphElement;
  delTargets: HTMLButtonElement[];
  urlValue: string;
  bulkUrlValue: string;
  userEmailValue: string;
  initialEmailValue: string;
  maxEmailsValue: number;

  emails = new Set<string>();

  connect() {
    const emails: string[] = this.initialEmailValue ? JSON.parse(this.initialEmailValue) : [];
    this.addEmails(emails);
    this.updateButtons();
  }

  updateButtons() {
    // @ts-ignore
    if (this.hasSubmitTarget) {
      this.submitTarget.disabled = this.bulkTarget.value != "" || this.emails.size == 0;
    }
    // @ts-ignore
    if (this.hasNextTarget && this.hasSkipTarget) {
      if (this.emails.size > 0) {
        this.nextTarget.style.display = 'flex';
        this.skipTarget.style.display = 'none';
      } else {
        this.skipTarget.style.display = 'flex';
        this.nextTarget.style.display = 'none';
      }
    }
  }

  async addbulk(event: Event) {
    event.preventDefault();
    const blob = this.bulkTarget.value;
    if (blob == "") {
      return;
    }
    console.log("send email blob", blob, "TO", this.bulkUrlValue);
    try {
      const response = await fetch(this.bulkUrlValue, {
        headers: { "X-CSRF-Token": metaCsrfToken()! },
        method: "POST",
        body: blob,
      });
      console.log(`bulk response: ${response.status} ${response.statusText} ${response.url}`);
      if (!response.ok) {
        // we print more details to the console when response isn't ok
        console.dir(response);
        return;
      }
      const emails = await response.json();
      this.bulkTarget.value = "";
      this.addEmails(emails);
      this.updateButtons();
    } catch (error) {
      console.log("bulk call failed:", error);
    }
  }

  delete(event: Event) {
    event.preventDefault();
    const button = event.currentTarget as HTMLButtonElement;
    const email = escapeHtml(button.value);
    this.emails.delete(email);
    this.rerenderEmails();
    this.updateButtons();
  }

  addEmails(emails: string[]) {
    for (let i in emails) {
      const email = emails[i];
      this.addEmail(email!);
    }
  }

  addEmail(email: string) {
    // TODO: When we do a better job of escaping things, modify the associated handlers
    // because they undo this escaping on submit.
    email = escapeHtml(email);
    if (this.emails.has(email)) {
      // avoid adding duplicates
      return;
    }
    if (this.maxEmailsValue && this.emails.size >= this.maxEmailsValue) {
      return;
    }
    this.emails.add(email);
    this.rerenderEmails();
  }

  rerenderEmails() {
    const fragments = Array.from(this.emails)
      .sort()
      .map((email) => {
        let warning = "";
        if (this.userEmailValue && email == this.userEmailValue) {
          warning = "This is you.";
        }
        if (warning != "") {
          warning = `<div class="text-sm text-red-600">⚠️ ${warning}</div>`;
        }
        const fragment = `
<div class="group flex items-center mt-6 gap-6">
  <div class="text-md text-gray-700">${email}</div>
  ${warning}
  <button data-recipients-target="del" data-action="recipients#delete" type="submit" name="delete" value="${email}">
    <div class="invisible group-hover:visible">${TRASHCAN}</div>
  </button>
  <input type="hidden" name="recipient" value="${email}">
</div>`;
        return fragment;
      });
    // TODO: maybe do something clever here to avoid an entire DOM tear-down and re-create.
    this.listTarget.innerHTML = fragments.join("");
    switch (fragments.length) {
      case 0:
        this.nTarget.textContent = "";
        break;
      case 1:
        this.nTarget.textContent = "1 recipient";
        break;
      default:
        this.nTarget.textContent = `${fragments.length} recipients`;
        break;
    }
  }
}

function escapeHtml(html: string) {
  var text = document.createTextNode(html);
  var p = document.createElement("p");
  p.appendChild(text);
  return p.innerHTML;
}
