import prettyBytes from "pretty-bytes";
import { Controller } from "stimulus";
import { useDebounce } from "../../debouce";

/**
 * Controller that shows the selected files for upload in a file input 
 * and shows errors for invalid file types or file sizes.
 * The acceptableFileTypes, maxFileUploadSize, and maxFileUploadSizeMessage
 * are expected to be passed in via the server rendered HTML.
 */
export class FileInput extends Controller {
  static debounces = ["open", "update"];
  static targets = ["input", "files"];
  static values = { maxFileUploadBytes: Number, maxTotalUploadBytes: Number };

  inputTarget: HTMLInputElement;
  filesTarget: HTMLDivElement;

  maxFileUploadBytesValue: number;
  maxTotalUploadBytesValue: number;

  // Split the string of acceptable files from the target input's "accept" attribute into an array
  get acceptableFileTypes(): string[] {
    return this.inputTarget.accept.split(",");
  }

  // Guess from the file type what the user might want to see in the UI
  // TODO probably want to make this more robust
  get friendlyFileTypes(): string[] {
    try {
      return this.acceptableFileTypes.map((type) => {
        const parts = type.split("/");
        if (parts !== undefined && parts.length === 2 && parts[1] !== undefined) {
          return parts[1].toUpperCase();
        }
        return type;
      });
    } catch (e) {
      return ["GIF", "JPG", "PNG", "PDF", "HEIC"];
    }
  }

  connect() {
    useDebounce(this);
    console.log(
      "FileUpload connected",
      this.inputTarget,
      this.filesTarget,
      this.acceptableFileTypes,
      this.maxFileUploadBytesValue,
      this.maxTotalUploadBytesValue
    );
  }

  open(event: Event) {
    console.log("FileUpload open", event);
    this.inputTarget.click();
  }

  update(event: Event) {
    console.log("FileUpload updated", event.type);
    console.dir(this.inputTarget.files);
    if (this.inputTarget.files && this.inputTarget.files.length > 0) {
      const fileList = this.inputTarget.files;
      const fileTmpl = (file: File, error: string | undefined) => {
        return `
        <li class="py-2 max-w-sm flex">
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </div>
          <div class="flex-1 ml-2">
            ${renderParagraph(file.name, "text-sm", "font-medium", "text-gray-900", "truncate")}
            ${renderParagraph(prettyBytes(file.size), "text-sm", "text-gray-500")}
            ${error ? renderError(error) : ""}
          </div>
        </li>`;
      };
      let listItemsHtml = "";
      let totalBytes = 0;
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file) {
          const error = this.validateFile(file);
          listItemsHtml += fileTmpl(file, error);
          totalBytes += file.size;
        }
      }
      let filesHtml = `<ul role="list" class="max-w-sm divide-y divide-gray-200">${listItemsHtml}</ul>`;
      if (totalBytes > this.maxTotalUploadBytesValue) {
        filesHtml += renderError(
          `total size of file uploads must be smaller than ${prettyBytes(this.maxTotalUploadBytesValue)}`
        );
      }
      this.filesTarget.innerHTML = filesHtml;
      this.filesTarget.classList.remove("hidden");
    } else {
      this.filesTarget.innerHTML = "";
      this.filesTarget.classList.add("hidden");
    }
  }

  // Checks that a file is an acceptable type and size and return undefined if it is, otherwise returns an error message
  validateFile(file: File): string | undefined {
    // Theoretically, the "accept" attribute on the input should limit the file types that can be selected
    // but it doesn't hurt to check the file type here as well.
    if (!this.acceptableFileTypes.includes(file.type)) {
      return `${file.name} is not a supported file type. Supported file types are: ${this.friendlyFileTypes}`;
    }
    const maxFileUploadSize = this.maxFileUploadBytesValue;
    if (file.size > maxFileUploadSize) {
      return `"${file.name}" is too large (${prettyBytes(file.size)}). Files must be less than ${prettyBytes(
        maxFileUploadSize
      )}`;
    }
    return undefined;
  }
}

function renderError(msg: string): string {
  return renderParagraph(msg, "text-xs", "text-red-500");
}

function renderParagraph(text: string, ...styles: string[]): string {
  const t = document.createTextNode(text);
  const p = document.createElement("p");
  p.classList.add(...styles);
  p.appendChild(t);
  return p.outerHTML;
}
