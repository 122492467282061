import { html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import { injectAppCss } from "./helpers";

/**
 * A horizontal bar with controls for fill percentage, colors, and height.
 */
@customElement("h-bar")
export class HBar extends LitElement {
  @property({ type: Number })
  fillPercentage: number;

  @property({ type: String })
  heightClass: string = "h-8";

  @property({ type: String })
  widthClass: string = "w-64";

  @property({ type: String })
  fillColorClass: string = "bg-brand-blue";

  @property({ type: String })
  emptyColorClass: string = "bg-gray-200";

  render() {
    // if fillPercentage is 0, we want to show the empty bar with rounded corners
    if (this.fillPercentage === 0) {
      return injectAppCss(html`
        <div class="flex ${this.heightClass} ${this.widthClass}">
          <div style="width: 100%" class="${this.emptyColorClass} rounded-md">&nbsp;</div>
        </div>
      `);
    }
    return injectAppCss(html`
      <div class="flex ${this.heightClass} ${this.widthClass}">
        <div style="width: ${this.fillPercentage}%" class="${this.fillColorClass} rounded-l-md">&nbsp;</div>
        <div style="width: ${100 - this.fillPercentage}%" class="${this.emptyColorClass} rounded-r-md">&nbsp;</div>
      </div>
    `);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    hbar: HBar;
  }
}
