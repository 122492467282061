// @ts-nocheck
// we have a lot of implicit "this" in this file because of Phoenix Hooks
// additionally we are loading the google places autocomplete library in the HTML
// but referencing that here as well so just turning off type checking for this file

/**
 * A Phoenix LiveView hook for Google Places Autocomplete.
 */
export const googlePlacesAutocomplete = {
  initAutocomplete() {
    // connect this element to the google places autocomplete library
    this.autocomplete = new google.maps.places.Autocomplete(this.el, {
      fields: ["address_components", "geometry"],
      componentRestrictions: {
        country: ["us"],
      },
    });
    this.autocomplete.addListener("place_changed", () => {
      // get google place object
      this.place = this.autocomplete.getPlace();
      // construct address object
      const address = new AddressWithGeometry(this.el.value, this.place.address_components ?? [], this.place.geometry);
      // push new address to LiveView
      this.pushEvent("place_changed", { ...address });
    });
  },
  mounted() {
    // add callback function to window
    window.initAutocomplete = this.initAutocomplete.bind(this);
    if (!document.getElementById("google-places-autocomplete")) {
      // append script tag to body
      const script = document.createElement("script");
      script.id = "google-places-autocomplete";
      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1X7FQoFUpk2Q4eZqOImUsHrRmPkuYXBY&libraries=places&callback=initAutocomplete&language=en`;
      document.body.appendChild(script);
    } else {
      this.initAutocomplete();
    }
  },
};

/**
 * Helper class for constructing an address object from Google Places Autocomplete.
 */
class AddressWithGeometry {
  // go style names for server side parsing
  Address: string; // formatted address
  StreetNumber: string;
  Route: string;
  City: string;
  County: string;
  State: string;
  Country: string;
  PostalCode: string;
  Longitude: string;
  Latitude: string;

  constructor(rawAddress: string, addressComponents: any[], geometry: any) {
    this.Address = rawAddress;

    const data: any = {};
    addressComponents.forEach((component) => {
      const type = component.types[0];
      data[type] = component.long_name;
    });

    // default to empty string for all address components
    this.StreetNumber = data.street_number || "";
    this.Route = data.route || "";
    this.City = data.locality || "";
    this.County = data.administrative_area_level_2 || "";
    this.State = data.administrative_area_level_1 || "";
    this.Country = data.country || "";
    this.PostalCode = data.postal_code || "";

    // set geometry
    if (geometry !== undefined) {
      this.Longitude = geometry.location.lng().toString();
      this.Latitude = geometry.location.lat().toString();
    }
  }
}
