import { Controller } from "stimulus";
import { useDebounce } from "../debouce";
import { turboPost } from "../turbo";

/**
 * FieldValidator is a generic controller meant to make it easy to run server-side
 * validation on a form field and display any errors to the user.
 */
export class FieldValidator extends Controller {
  static debounces = ["validate"];
  static targets = ["input"];
  static values = { url: String, relatedInputIds: String };

  inputTarget: HTMLInputElement;
  urlValue: string;
  relatedInputIdsValue: string;
  hasRelatedInputIdsValue: boolean;

  async connect() {
    useDebounce(this);
  }

  async validate() {
    // get data and field name to validate
    const value = this.inputTarget.value?.trim();
    const name = this.inputTarget.name;

    // get related input data and pass along to server
    let relatedData: { [key: string]: string } = {};
    if (this.hasRelatedInputIdsValue) {
      relatedData = this.relatedInputIdsValue?.split(",").reduce((acc, id) => {
        // assume ids are input elements
        const i = document.getElementById(id) as HTMLInputElement;
        if (i) {
          acc[i.name] = i.value?.trim();
        } else {
          console.error("Could not find related input element with id", id);
        }
        return acc;
      }, {} as { [key: string]: string });
    }

    // build post data
    const bodyData = new URLSearchParams({
      [name]: value,
      ["_target"]: name, // tell the server the target field
      ...relatedData,
    });

    // post to server
    await turboPost(this.urlValue, bodyData);
  }
}
