import { Controller } from "stimulus";
import { turboPost } from "../turbo";

/**
 * Handles setting the locale for the user from the language dropdown.
 */
export class SetLocaleController extends Controller {
  static values = { url: String, initialLocaleCode: String, localeCode: String };

  urlValue: string;
  localeCodeValue: string;
  initialLocaleCodeValue: string;

  connect(): void {
      console.log("SetLocaleController.connect", this.urlValue, this.localeCodeValue, this.initialLocaleCodeValue);
  }

  async localeCodeValueChanged() {
    if(this.localeCodeValue === this.initialLocaleCodeValue || this.localeCodeValue == "") {
        return;
    }
    // accumulate all the data to send
    const postData = new URLSearchParams({
        "_target": "locale",
        "locale": this.localeCodeValue,
    });
    console.log("SetLocaleController.update", this.urlValue, this.localeCodeValue, postData);

    // send validation request and reload page on success
    await turboPost(this.urlValue, postData, {
      onResponse: (response) => {
        window.location.reload();
      }
    });
  }
}
