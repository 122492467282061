import { Controller } from "stimulus";

/**
 * Handles updating the RankedPrefs widget rank order and updating the
 * hidden inputs with those orders as well.
 */
export class RankedPrefsController extends Controller {
  static targets = ["item", "input"];

  itemTargets: HTMLElement[];
  inputTargets: HTMLInputElement[];

  handleReorder(event: CustomEvent) {
    // update the order of the items and inputs based on new DOM order
    this.itemTargets.forEach((item, index) => {
      item.innerHTML = `${index + 1}`;
    });
    this.inputTargets.forEach((input, index) => {
      input.value = index + ""; // convert to string
    });
  }
}
