import { Controller } from "stimulus";
import tippy from "tippy.js";
import "tippy.js/dist/svg-arrow.css";

export class Tooltip extends Controller {
  static values = { domId: String };

  domIdValue: string;

  connect() {
    console.log("Tooltip connected", this.domIdValue);
    const tooltipContent = document.getElementById(this.domIdValue);
    if (!tooltipContent) {
      console.error(`Tooltip content not found for id ${this.domIdValue}`);
      return;
    }

    tippy(this.element, {
      content: tooltipContent.innerHTML,
      allowHTML: true,
      interactive: true
    });
  }
}
