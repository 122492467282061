import confetti from "canvas-confetti";
import { Socket } from "phoenix";
import { LiveSocket } from "phoenix_live_view";
import topbar from "topbar";

import { Mixpanel } from "./mixpanel";
import { googlePlacesAutocomplete } from "./places_autocomplete";

// Hook for Google Places Autocomplete
const Hooks: { [key: string]: any } = {};
Hooks["googlePlacesAutocomplete"] = googlePlacesAutocomplete;
Hooks["scrollTop"] = {
  mounted() {
    window.scrollTo(0, 0);
  },
};
Hooks["selectUpdate"] = {
  mounted() {
    // get event and name of detail key to update based on
    const event = this.el.dataset.selectUpdateEvent;
    const detailKey = this.el.dataset.selectUpdateDetailKey;
    if (!event || !detailKey) return;
    // listen for event and update select value
    this.handleEvent(event, (detail: any) => {
      const s = this.el as HTMLSelectElement;
      for (var i = 0; i < s.options.length; i++) {
        const item = s.options[i];
        if (item?.value === detail[detailKey]) {
          s.options.selectedIndex = i;
          break;
        }
      }
    });
  },
};
Hooks["confetti"] = {
  mounted() {
    console.log("mounting confetti hook")
    this.handleEvent("confetti", () => {
      console.log("confetti hook for", this.el)
      const el = (this.el as HTMLElement);
      const rect = el.getBoundingClientRect();
      console.log(rect);
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: {
          x: (rect.x + (rect.width / 2)) / vw,
          y: (rect.y + (rect.height / 8)) / vh
        },
        zIndex: 1,
      });
    })
  }
}

// LiveView
let csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute("content");
let liveSocket = new LiveSocket("/live", Socket, {
  params: { _csrf_token: csrfToken },
  hooks: Hooks,
});

// Show progress bar on live navigation and form submits
topbar.config({ barColors: { 0: "#0043FF" }, shadowColor: "rgba(0, 0, 0, .3)" });
window.addEventListener("phx:page-loading-start", (_info) => topbar.show(200));
window.addEventListener("phx:page-loading-stop", (e) => {
  console.log("phx:page-loading-stop event:", e, (e as CustomEvent).detail);
  // only send profile data on initial page load
  if ((e as CustomEvent<{ kind: string; to?: string }>).detail.kind === "initial") {
    // get HTML tag
    const doc = document.documentElement;
    // create profile data from tag
    const profile = {
      userId: doc.dataset.analyticsViewsUserIdValue ?? "",
      emailDomain: doc.dataset.analyticsViewsEmailDomainValue ?? "",
      isEmployee: doc.dataset.analyticsViewsIsEmployeeValue === "true",
      isEmployer: doc.dataset.analyticsViewsIsEmployerValue === "true",
    };
    // send profile data to mixpanel
    Mixpanel.setIdAndProfile(profile);
  }
  // send page view data to mixpanel
  Mixpanel.pageView(location.pathname);
  topbar.hide();
});

// connect if there are any LiveViews on the page
liveSocket.connect();

// add event listener for generic js-exec events from server
// this works by adding a data attribute to the element with the js to execute
// and then triggering a custom event with the selector to find the element
// see: https://fly.io/phoenix-files/server-triggered-js/
window.addEventListener("phx:js-exec", (e: Event) => {
  const detail = (e as CustomEvent).detail;
  document.querySelectorAll(detail.to).forEach(el => {
      liveSocket.execJS(el, el.getAttribute(detail.attr))
  })
})


// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)  // enabled for duration of browser session
// >> liveSocket.disableLatencySim()
// @ts-ignore
export { liveSocket };
