import { Controller } from "@hotwired/stimulus";

/**
 * Fetches data from a server and publishes the data in a 'fetchresult'
 * event (the 'detail' specifically).  You can pass "params" into the post
 * by defining 'data-fetch-YOURPARAM-param'.  (See https://stimulus.hotwired.dev/reference/actions#action-parameters)
 * The handler is expected to check if the user is authorized to make the request.
 */
export class FetchData extends Controller {
  static values = {
    url: String, // the URL from which to fetch
  };

  // value types
  urlValue: string;

  connect() {
    console.log("FetchData", this.urlValue);
  }

  async doFetch(event: Event) {
    console.log("doFetch", event);
    event.preventDefault();
    event.stopPropagation();

    // get params from event
    // @ts-ignore
    const params = event.params;

    const csrfToken = (document!.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content || "";
    try {
      const res = await fetch(this.urlValue, {
        headers: { "X-CSRF-Token": csrfToken },
        method: "POST",
        body: new URLSearchParams(params),
      });
      if (res.ok) {
        const data = await res.json();
        this.dispatch("fetchresult", {
          detail: { data },
          bubbles: true,
          prefix: "", // removes the stimulus prefix from the event name
        });
      }
    } catch (err) {
      console.error("error fetching ", err);
    }
  }
}
