import { Controller } from "stimulus";

export class ReadMore extends Controller {
  static targets = ["more", "less", "content"];
  static values = { clipAt: Number };

  contentTarget: HTMLSpanElement;
  moreTarget: HTMLSpanElement;
  lessTarget: HTMLSpanElement;
  clipAtValue: number;
  fullText: string;
  expanded = false;

  connect() {
    console.log("ReadMore connected", this.clipAtValue);

    if (!(this.element instanceof HTMLParagraphElement)) {
      return;
    }

    this.fullText = this.contentTarget.innerText;
    this.showNoHints();

    if (this.shouldClip()) {
      this.clip();
      this.moreTarget.addEventListener("click", this.toggle.bind(this));
      this.lessTarget.addEventListener("click", this.toggle.bind(this));
    }
  }

  fullWords(): string[] {
    return this.fullText.split(' ');
  }

  shouldClip(): boolean {
    return this.fullWords().length > this.clipAtValue;
  }

  clip() {
    let out = this.fullText;
    this.expanded = true;
    if (this.shouldClip()) {
      out = this.fullWords().slice(0, this.clipAtValue).join(' ') + '…';
      this.expanded = false;
    }
    this.contentTarget.innerText = out;
    this.showReadMoreHint();
  }

  expand() {
    this.contentTarget.innerText = this.fullText;
    this.expanded = true;
    this.showReadLessHint();
  }

  showReadMoreHint() {
    this.lessTarget.style.display = "none";
    this.moreTarget.style.display = "inline";
  }

  showReadLessHint() {
    this.lessTarget.style.display = "inline";
    this.moreTarget.style.display = "none";
  }

  showNoHints() {
    this.lessTarget.style.display = "none";
    this.moreTarget.style.display = "none";
  }

  toggle() {
    if (!this.expanded) {
      this.expand();
    } else {
      this.clip();
    }
  }
}
