import { Controller } from "stimulus";
import { SPINNER } from "../icons";
import { turboPost } from "../turbo";

export class WaitlistButton extends Controller {
  static targets = ["button", "text"];
  static values = { url: String, initialText: String, successText: String };

  buttonTarget: HTMLButtonElement;
  textTarget: HTMLSpanElement;
  statusTarget: HTMLDivElement;
  urlValue: string;
  initialTextValue: string;
  successTextValue: string;

  connect() {
    this.textTarget.innerText = this.initialTextValue;
  }

  async click() {
    const buttonTarget = this.buttonTarget;
    if (buttonTarget.getAttribute("data-completed") != null) {
      return;
    }

    const textTarget = this.textTarget;
    const initialText = this.initialTextValue;
    const successText = this.successTextValue;
    textTarget.innerHTML = SPINNER;

    // build post data
    const bodyData = new URLSearchParams({});

    // post to server
    await turboPost(this.urlValue, bodyData, {
      onResponse: (response) => {
        if (response.ok) {
          textTarget.innerHTML = successText;
          buttonTarget.setAttribute("data-completed", "true");
        } else {
          textTarget.innerHTML = initialText;
        }
      },
      onError() {
        textTarget.innerHTML = initialText;
      },
    });
  }
}
