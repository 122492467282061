import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "../debouce";
import { turboPost } from "../turbo";

/**
 * Controller to handle text input with autocomplete including existing data from
 * the form that this input is part of.
 */
export class Autocomplete extends Controller {
  static debounces = ["handleInput", "handleBlur"];
  static targets = ["input"];
  static values = {
    searchUrl: String,
    finalizeUrl: String,
    nameOverride: String,
    otherInputNames: String,
    otherInputNamesOverride: String,
  };

  // target types
  inputTarget: HTMLInputElement;

  // value types
  searchUrlValue: string;
  hasSearchUrlValue: boolean;
  finalizeUrlValue: string;
  hasFinalizeUrlValue: boolean;
  nameOverrideValue: string;
  hasNameOverrideValue: boolean;
  otherInputNamesValue: string;
  hasOtherInputNamesValue: boolean;
  otherInputNamesOverrideValue: string;
  hasOtherInputNamesOverrideValue: boolean;

  mouseDown: boolean;

  connect() {
    useDebounce(this);
  }

  handleBlur() {
    // otherwise search on the input
    const search = this.inputTarget.value?.trim();
    if (search && search.length > 0 && this.hasFinalizeUrlValue) {
      this.fetchResults(search, this.finalizeUrlValue);
    }
  }

  handleInput(event: InputEvent) {
    console.log("handleInput", event, event.inputType);
    // when inputType is undefined it means a user has selected an option
    // and we don't want to search again on the selected option
    if (event.inputType === undefined) {
      this.handleBlur();
      this.dispatch("searchComplete");
      event.stopPropagation();
      return;
    }

    // otherwise search on the input
    const search = this.inputTarget.value?.trim();
    if (search && search.length > 0 && this.hasSearchUrlValue) {
      this.fetchResults(search, this.searchUrlValue);
    }
  }

  async fetchResults(query: string, url: string) {
    try {
      // use input.name by default or overriden name if provided
      const searchName = this.hasNameOverrideValue ? this.nameOverrideValue : this.inputTarget.name;
      const postData = {
        targetID: `autocomplete-results-${this.inputTarget.name}`,
        [searchName]: query,
      };

      // check if we need to pass in other data from the form
      if (this.hasOtherInputNamesValue) {
        // get parent form from the input target
        const formData = new FormData(this.inputTarget.form!);
        // formData.get doesn't return value for text inputs
        // so we need to iterate over all form elements and get their values
        const getFormData = (keyName: string): string => {
          let ret: string = "";
          formData.forEach((value, key) => {
            if (key === keyName) {
              ret = value.toString();
            }
          });
          return ret;
        };
        // iterate over other input names and add them to the postData object
        this.otherInputNamesValue.split(/,s+/).forEach((name, i) => {
          // override the name to pass in as well
          const overrideName = this.otherInputNamesOverrideValue.split(/,s+/)[i]!;
          postData[overrideName] = getFormData(name);
        });
      }

      // post to url with all the data
      await turboPost(url, new URLSearchParams(postData));
    } catch (error) {
      throw error;
    }
  }
}
