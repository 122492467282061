import mixpanel from "mixpanel-browser";

export namespace Mixpanel {
  type IdAndProfile = {
    userId: string;
    emailDomain: string;
  };

  /**
   * Sets the user's ID and profile properties in mixpanel
   * @param p the user's ID and profile properties
   */
  export function setIdAndProfile(p: IdAndProfile) {
    // Set Identity and Profile Properties if we have a userId
    if (p.userId !== "") {
      mixpanel.identify(p.userId);
      if (p.emailDomain !== "") {
        mixpanel.people.set({ EmailDomain: p.emailDomain });
      }
    }
  }

  /**
   * Tracks a page view in mixpanel
   * @param page the pathname of the URL to track
   */
  export function pageView(page: string) {
    mixpanel.track("Page View", { page });
  }
}
