import { Controller } from "stimulus";

/**
 * A controller that posts to the server when its target changes.
 * Does not update input state on request—requests are made optimistically.
 */
export class Updater extends Controller {
  static values = { url: String };
  static targets = ["input"];

  urlValue: string;
  inputTarget: HTMLInputElement;

  connect() {
    console.log("Updater", this.urlValue, this.inputTarget);
    this.inputTarget.addEventListener("change", this.update.bind(this));
  }

  async update() {
    const value = this.inputTarget.type == "checkbox" ? `${this.inputTarget.checked}` : this.inputTarget.value;
    const csrfToken = (document!.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content || "";
    fetch(this.urlValue, {
      headers: { "X-CSRF-Token": csrfToken },
      method: "POST",
      body: new URLSearchParams({
        [this.inputTarget.name]: value
      }),
    })
  }
}
