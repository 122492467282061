import { Controller } from "stimulus";
import { Mixpanel } from "../../mixpanel";

/**
 * Connects browser events to mixpanel by watching for Turbo events including
 * `turbo:load` and `turbo:frame-load`.  It is expected this controller will be
 * connected to the root "html" element which is where Turbo events are dispatched.
 */
export class AnalyticsPageViewsController extends Controller {
  static values = {
    // general user info
    userId: String,
    emailDomain: String,
  };

  userIdValue: string;
  emailDomainValue: string;

  // Tracks initial page load and any full page reloads
  connect() {
    console.log("AnalyticsPageViewsController connected");
    console.log("userId", this.userIdValue);
    console.log("emailDomain", this.emailDomainValue);

    const p = {
      userId: this.userIdValue,
      emailDomain: this.emailDomainValue,
    };
    Mixpanel.setIdAndProfile(p);

    // track the initial page load
    Mixpanel.pageView(location.pathname);
    // check if url is /logout and if so, call reset
    if (location.pathname.startsWith("/logout")) {
      console.log("logging out");
      // TODO - hmmm.  I don't expect most people to share devices so seem like
      // we should not reset the mixpanel cookie.  Read more here:
      // https://developer.mixpanel.com/docs/javascript#call-reset-at-logout
      // mixpanel.reset();
    }
  }

  // Tracks turbo:load-based Page Views
  load(e: CustomEvent) {
    console.log("AnalyticsPageViewsController load", e);
    const url = new URL(e.detail.url);
    Mixpanel.pageView(url.pathname);
  }

  // Tracks turbo:frame-load-based Page Views (e.g. "Why" and "Plan" frame)
  frameLoad(e: CustomEvent) {
    console.log("AnalyticsPageViewsController frameLoad", e);
    const url = new URL((e.target as any).src);
    Mixpanel.pageView(url.pathname);
  }
}
