import { Controller } from "@hotwired/stimulus";


export class Clipboard extends Controller {
  static targets = ["button"];
  static values = {
    text: String, // Text to copy on button press
  };

  textValue: string;
  buttonTarget: HTMLButtonElement;

  connect() {
    console.log("Clipboard", this.textValue);
  }

  copy() {
    window.navigator.clipboard.writeText(this.textValue);
    const contents = this.buttonTarget.textContent;
    this.buttonTarget.textContent = "Copied!";
    setTimeout(() => {
      this.buttonTarget.textContent = contents;
    }, 2000);
  }
}
