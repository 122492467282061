import mixpanel from "mixpanel-browser";
import { Controller } from "stimulus";

const parseBool = (s: string) => s === "true";

interface ScoredInterest {
  benefitType: string;
  impactScore: number;
  interested: boolean;
}

interface CommuteMethod {
  method_type: string;
  vehicle_id: number;
  pct: number;
}

/**
 * Passes user info to mixpanel when the element connected to this controller
 * is loaded in the DOM.  Expected this will be used on the employee "home"
 * screen and other pages where employee profile data may be updated.
 */
export class AnalyticsProfileController extends Controller {
  static values = {
    // home info
    postalCode: String,
    state: String,
    county: String,
    homeSize: String,
    ownership: String,
    homeVintage: String,
    insulated: String,
    hasEnergyAudit: String,
    housingType: String,
    occupants: String,
    moistureIssues: String,
    coolingType: String,
    spaceHeatDistribution: String,
    spaceHeatEnergy: String,
    hasSecondarySpaceHeat: String,
    secondarySpaceHeatDistribution: String,
    secondarySpaceHeatEnergy: String,
    waterHeaterEnergy: String,
    cookingEnergy: String,
    clothesDryerEnergy: String,
    gridPlan: String,
    solar: String,
    homeBattery: String,
    portableHeatPump: String,
    thermostatType: String,
    panelAmps: String,
    // transportation
    hasEBike: String,
    vehiclesJson: String, // json string of array of vehicle objects
    // commute
    commuteCommunityId: String,
    commuteWfhDaysPerWeek: String,
    commuteCommuteDaysPerWeek: String,
    commuteCommuteDistance: String,
    commuteMethodsJson: String,
    // interests
    scoredInterestsJson: String, // json string of array of scoredInterests
    // save event
    saveEvent: String,
  };

  postalCodeValue: string;
  stateValue: string;
  countyValue: string;
  homeSizeValue: string;
  ownershipValue: string;
  homeVintageValue: string;
  insulatedValue: string;
  hasEnergyAuditValue: string;
  housingTypeValue: string;
  occupantsValue: string;
  moistureIssuesValue: string;
  coolingTypeValue: string;
  spaceHeatDistributionValue: string;
  spaceHeatEnergyValue: string;
  hasSecondarySpaceHeatValue: string;
  secondarySpaceHeatDistributionValue: string;
  secondarySpaceHeatEnergyValue: string;
  waterHeaterEnergyValue: string;
  cookingEnergyValue: string;
  clothesDryerEnergyValue: string;
  gridPlanValue: string;
  solarValue: string;
  homeBatteryValue: string;
  portableHeatPumpValue: string;
  thermostatTypeValue: string;
  panelAmpsValue: string;
  hasEBikeValue: string;
  vehiclesJsonValue: string;
  commuteCommunityIdValue: string;
  commuteWfhDaysPerWeekValue: string;
  commuteCommuteDaysPerWeekValue: string;
  commuteCommuteDistanceValue: string;
  commuteMethodsJsonValue: string;
  interestsJsonValue: string;
  scoredInterestsJsonValue: string;
  saveEventValue: string;

  // Attempts to set the mixpanel profile properties for the current user when
  // the element is connected to the DOM.
  connect() {
    console.log("AnalyticsProfileController connected");

    this.postalCodeValueChanged();
    this.stateValueChanged();
    this.countyValueChanged();
    this.homeSizeValueChanged();
    this.ownershipValueChanged();
    this.homeVintageValueChanged();
    this.insulatedValueChanged();
    this.hasEnergyAuditValueChanged();
    this.housingTypeValueChanged();
    this.occupantsValueChanged();
    this.moistureIssuesValueChanged();
    this.coolingTypeValueChanged();
    this.spaceHeatDistributionValueChanged();
    this.spaceHeatEnergyValueChanged();
    this.hasSecondarySpaceHeatValueChanged();
    this.secondarySpaceHeatDistributionValueChanged();
    this.secondarySpaceHeatEnergyValueChanged();
    this.waterHeaterTypeValueChanged();
    this.cookingEnergyValueChanged();
    this.clothesDryerEnergyValueChanged();
    this.gridPlanValueChanged();
    this.solarValueChanged();
    this.homeBatteryValueChanged();
    this.portableHeatPumpValueChanged();
    this.thermostatTypeValueChanged();
    this.panelAmpsValueChanged();

    this.hasEBikeValueChanged();
    this.vehiclesJsonValueChanged();

    this.commuteCommunityIdValueChanged();
    this.commuteWfhDaysPerWeekValueChanged();
    this.commuteCommuteDaysPerWeekValueChanged();
    this.commuteCommuteDistanceValueChanged();
    this.commuteMethodsJsonValueChanged();

    this.scoredInterestsJsonValueChanged();

    this.saveEventValueChanged();
  }

  postalCodeValueChanged() {
    console.log("postalCode", this.postalCodeValue);
    this.setIfNotEmpty("PostalCode", this.postalCodeValue);
  }

  stateValueChanged() {
    console.log("state", this.stateValue);
    this.setIfNotEmpty("State", this.stateValue);
  }

  countyValueChanged() {
    console.log("county", this.countyValue);
    this.setIfNotEmpty("County", this.countyValue);
  }

  homeSizeValueChanged() {
    console.log("homeSize", this.homeSizeValue);
    this.setIfNotEmpty("HomeSize", this.homeSizeValue, parseInt);
  }

  ownershipValueChanged() {
    console.log("ownership", this.ownershipValue);
    this.setIfNotEmpty("Ownership", this.ownershipValue);
  }

  homeVintageValueChanged() {
    console.log("homeVintage", this.homeVintageValue);
    this.setIfNotEmpty("HomeVintage", this.homeVintageValue);
  }

  insulatedValueChanged() {
    console.log("insulated", this.insulatedValue);
    this.setIfNotEmpty("Insulated", this.insulatedValue);
  }

  hasEnergyAuditValueChanged() {
    console.log("hasEnergyAudit", this.hasEnergyAuditValue);
    this.setIfNotEmpty("HasEnergyAudit", this.hasEnergyAuditValue, parseBool);
  }

  housingTypeValueChanged() {
    console.log("housingType", this.housingTypeValue);
    this.setIfNotEmpty("HousingType", this.housingTypeValue);
  }

  occupantsValueChanged() {
    console.log("occupants", this.occupantsValue);
    this.setIfNotEmpty("Occupants", this.occupantsValue, parseInt);
  }

  moistureIssuesValueChanged() {
    console.log("moistureIssues", this.moistureIssuesValue);
    this.setIfNotEmpty("MoistureIssues", this.moistureIssuesValue);
  }

  coolingTypeValueChanged() {
    console.log("coolingType", this.coolingTypeValue);
    this.setIfNotEmpty("CoolingType", this.coolingTypeValue);
  }

  spaceHeatDistributionValueChanged() {
    console.log("spaceHeatDistribution", this.spaceHeatDistributionValue);
    this.setIfNotEmpty("SpaceHeatDistribution", this.spaceHeatDistributionValue);
  }

  spaceHeatEnergyValueChanged() {
    console.log("spaceHeatEnergy", this.spaceHeatEnergyValue);
    this.setIfNotEmpty("SpaceHeatEnergy", this.spaceHeatEnergyValue);
  }

  hasSecondarySpaceHeatValueChanged() {
    console.log("hasSecondarySpaceHeat", this.hasSecondarySpaceHeatValue);
    this.setIfNotEmpty("HasSecondarySpaceHeat", this.hasSecondarySpaceHeatValue, parseBool);
  }

  secondarySpaceHeatDistributionValueChanged() {
    console.log("secondarySpaceHeatDistribution", this.secondarySpaceHeatDistributionValue);
    this.setIfNotEmpty("SecondarySpaceHeatDistribution", this.secondarySpaceHeatDistributionValue);
  }

  secondarySpaceHeatEnergyValueChanged() {
    console.log("secondarySpaceHeatEnergy", this.secondarySpaceHeatEnergyValue);
    this.setIfNotEmpty("SecondarySpaceHeatEnergy", this.secondarySpaceHeatEnergyValue);
  }

  waterHeaterTypeValueChanged() {
    console.log("waterHeaterType", this.waterHeaterEnergyValue);
    this.setIfNotEmpty("WaterHeaterType", this.waterHeaterEnergyValue);
  }

  cookingEnergyValueChanged() {
    console.log("cookingEnergy", this.cookingEnergyValue);
    this.setIfNotEmpty("CookingEnergy", this.cookingEnergyValue);
  }

  clothesDryerEnergyValueChanged() {
    console.log("clothesDryerEnergy", this.clothesDryerEnergyValue);
    this.setIfNotEmpty("ClothesDryerEnergy", this.clothesDryerEnergyValue);
  }

  gridPlanValueChanged() {
    console.log("gridPlan", this.gridPlanValue);
    this.setIfNotEmpty("GridPlan", this.gridPlanValue);
  }

  solarValueChanged() {
    console.log("solar", this.solarValue);
    this.setIfNotEmpty("Solar", this.solarValue);
  }

  homeBatteryValueChanged() {
    console.log("homeBattery", this.homeBatteryValue);
    this.setIfNotEmpty("HomeBattery", this.homeBatteryValue);
  }

  portableHeatPumpValueChanged() {
    console.log("portableHeatPump", this.portableHeatPumpValue);
    this.setIfNotEmpty("PortableHeatPump", this.portableHeatPumpValue);
  }

  thermostatTypeValueChanged() {
    console.log("thermostatType", this.thermostatTypeValue);
    this.setIfNotEmpty("ThermostatType", this.thermostatTypeValue);
  }

  panelAmpsValueChanged() {
    console.log("panelAmps", this.panelAmpsValue);
    this.setIfNotEmpty("PanelAmps", this.panelAmpsValue, parseInt);
  }

  hasEBikeValueChanged() {
    console.log("hasEBike", this.hasEBikeValue);
    this.setIfNotEmpty("HasEBike", this.hasEBikeValue, parseBool);
  }

  vehiclesJsonValueChanged() {
    console.log("vehiclesJson", this.vehiclesJsonValue);
    if (this.vehiclesJsonValue !== "") {
      const vehicles: Vehicle[] = JSON.parse(this.vehiclesJsonValue);
      vehicles.forEach((v, i) => {
        this.setIfNotEmpty(`Vehicle.${i}.Year`, v.year + "", parseInt);
        this.setIfNotEmpty(`Vehicle.${i}.MakeModel`, v.make_model);
        this.setIfNotEmpty(`Vehicle.${i}.MilesPerYear`, v.miles_per_year + "", parseInt);
      });
    }
  }

  commuteCommunityIdValueChanged() {
    console.log("commuteCommunityId", this.commuteCommunityIdValue);
    this.setIfNotEmpty("CommuteCommunityId", this.commuteCommunityIdValue);
  }

  commuteWfhDaysPerWeekValueChanged() {
    console.log("commuteWfhDaysPerWeek", this.commuteWfhDaysPerWeekValue);
    this.setIfNotEmpty("CommuteWfhDaysPerWeek", this.commuteWfhDaysPerWeekValue);
  }

  commuteCommuteDaysPerWeekValueChanged() {
    console.log("commuteCommuteDaysPerWeek", this.commuteCommuteDaysPerWeekValue);
    this.setIfNotEmpty("CommuteCommuteDaysPerWeek", this.commuteCommuteDaysPerWeekValue);
  }

  commuteCommuteDistanceValueChanged() {
    console.log("commuteCommuteDistance", this.commuteCommuteDistanceValue);
    this.setIfNotEmpty("CommuteCommuteDistance", this.commuteCommuteDistanceValue, parseInt);
  }

  commuteMethodsJsonValueChanged() {
    console.log("commuteMethodsJson", this.commuteMethodsJsonValue);
    this.setIfNotEmpty("CommuteMethodsJSON", this.commuteMethodsJsonValue);
  }

  scoredInterestsJsonValueChanged() {
    console.log("scoredInterestsJson", this.scoredInterestsJsonValue);
    if (this.scoredInterestsJsonValue !== "") {
      const interests: ScoredInterest[] = JSON.parse(this.scoredInterestsJsonValue);
      interests.forEach((si) => {
        this.setIfNotEmpty(`${si.benefitType}_interest`, si.interested);
        this.setIfNotEmpty(`${si.benefitType}_impact`, si.impactScore);
      });
    }
  }

  saveEventValueChanged() {
    console.log("saveEvent", this.saveEventValue);
    if (this.saveEventValue !== "") {
      mixpanel.track(this.saveEventValue);
    }
  }

  private setIfNotEmpty(key: string, value: any, parseNotEmptyFn: (s: any) => any = (v) => v) {
    if (value !== "") {
      const parsedValue = parseNotEmptyFn(value);
      console.log("setting", key, parsedValue);
      mixpanel.people.set({ [key]: parsedValue });
    }
  }
}

// type the vehicle object
type Vehicle = {
  make_model: string;
  year: number;
  miles_per_year: number;
};
