import { StimulusCustomEvent } from "src/event";
import { SPINNER } from "src/icons";
import { turboPost } from "src/turbo";
import { Controller } from "stimulus";

type Params = { userId: string; url: string };
type Detail = {
  type: "addEmployerRole" | "removeEmployerRole" | "deactivate" | "activate";
};
type UseChangeEvent = StimulusCustomEvent<Detail, Params>;

export class UserMgmtChange extends Controller {
  async connect() {
    console.log("UserMgmtChange connected");
  }

  async changeUser(event: UseChangeEvent) {
    console.log("change user event", event.detail, event.params);

    this.element.innerHTML = SPINNER;

    // build post data
    const bodyData = new URLSearchParams({
      UserID: event.params.userId,
      Change: event.detail.type,
    });

    // post to server
    await turboPost(event.params.url, bodyData);
  }
}
