import { html, TemplateResult } from "lit";

/**
 * Injects our app.css into the Shadow DOM of the component so we can
 * use Tailwind classes in our components.
 * @param tmpl the template to inject the CSS into
 * @returns the template with the CSS injected
 */
export const injectAppCss = (tmpl: TemplateResult<any>) => html`  
  <link rel="stylesheet" href="/css/app.css"></link>
  ${tmpl}
`;
