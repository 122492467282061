import { Controller } from "stimulus";

/**
 * Controller that clears the hidden places-autocomplete inputs
 * when the user updates the input field.
 */
export class PlacesAutoclear extends Controller {
  static targets = ["input"];

  inputTargets: HTMLInputElement[];

  connect() {
    console.log("PlacesAutoclear connected", this.inputTargets);
  }

  clear(event: Event) {
    console.log("PlacesAutoclear updated", event.type);
    this.inputTargets.forEach((input) => {
      input.value = "";
    });
  }
}
