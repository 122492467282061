import { turboPost } from "src/turbo";
import { Controller } from "stimulus";

export class GuideStepController extends Controller {
  static values = { id: String };

  idValue: string;

  async connect() {
    console.log(`[GUIDE] step ${this.idValue} connected`);
  }

  complete() {
    turboPost("", new URLSearchParams({
      action: "complete",
      stepID: this.idValue
    }))
  }
}
