import { Controller } from "stimulus";

export class AdminInput extends Controller {
  static targets = ["input", "alert"];
  static values = { userId: String, companyId: String, url: String };

  inputTarget: HTMLInputElement;
  alertTarget: HTMLElement | null;
  userIdValue: string;
  companyIdValue: string;
  urlValue: string;

  async update() {
    const el = this.inputTarget;
    console.log("admin input changed", this.userIdValue, this.companyIdValue, el.id);
    el.classList.add("animate-pulse");
    var want: any;
    if (el.type === "checkbox") {
      want = el.checked;
    } else if (el.type === "select-one") {
      want = el.value;
    }

    if (this.alertTarget) {
      this.alertTarget.innerHTML = "";
    }

    // get csrf token from meta
    const csrfToken = (document!.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)?.content || "";
    fetch(this.urlValue, {
      headers: { "X-CSRF-Token": csrfToken },
      method: "POST",
      body: new URLSearchParams({
        ["user_id"]: this.userIdValue,
        ["company_id"]: this.companyIdValue,
        ["want"]: "" + want,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          const result = await response.json();
          if (el.type === "checkbox") {
            el.checked = result.got === "true";
          } else if (el.type === "select-one") {
            el.value = result.got;
          }
        } else {
          if (this.alertTarget) {
            this.alertTarget.innerHTML = "This superuser update failed. Check the console for details.";
          }
          console.log("update failed", response);
        }
        el.classList.remove("animate-pulse");
      })
      .catch(response => {
        if (this.alertTarget) {
          this.alertTarget.innerHTML = "This superuser update failed. Check the console for details.";
        }
        console.log("update failed", response);
        el.classList.remove("animate-pulse");
      });
    return true;
  }
}
