import { Controller } from "stimulus";
import Sortable from "sortablejs";

/**
 * Generic sortable controller that can be used to sort "draggable" elements and when complete
 * dispatches a "sortable:end" event with the details of the sort in the event.detail object.
 * Uses the Sortable.js library.
 */
export class SortableController extends Controller {
  static values = { handle: String };

  sortable: Sortable;
  handleValue: string;

  connect() {
    this.sortable = new Sortable(this.element as HTMLElement, {
      handle: `.${this.handleValue}`,
      animation: 150,
      onEnd: (event) => {
        this.dispatch("end", { detail: event });
      },
    });
  }

  disconnect() {
    this.sortable.destroy();
  }
}
