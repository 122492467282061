import { Controller } from "stimulus";
import { useDebounce } from "../debouce";
import { turboPost } from "../turbo";

/**
 * Handles sending pre-approval data to the server and displaying errors to the user.
 */
export class PreApprovalValidator extends Controller {
  static debounces = ["validate"];
  static targets = ["input"];
  static values = { url: String };

  inputTargets: HTMLInputElement[];
  urlValue: string;

  async connect() {
    useDebounce(this);
  }

  async validate() {
    // accumulate all the data to send
    const postData = new URLSearchParams(
      this.inputTargets.reduce((acc, input) => {
        acc[input.name] = input.value.trim();
        return acc;
      }, {} as { [key: string]: string })
    );

    // send validation request
    await turboPost(this.urlValue, postData);
  }
}
