import * as Turbo from "@hotwired/turbo";
import { metaCsrfToken } from "../csrf";

/**
 * Options to pass into turboPost to override error handling, success handling, and csrfToken
 * @param onError callback function when a non-server error occurs
 * @param onResponse callback function when a server response is received (regardless of HTTP status code)
 * @param csrfToken override the csrf token to use for the request
 */
interface TurboPostOptions {
  onError?: (error: Error) => void;
  onResponse?: (response: Response) => void;
  csrfToken?: string;
}

/**
 * Makes an HTTP POST request to endpointUrl with the provided bodyData and attempts to update the page with the Turbo stream response (based on matching the Content-Type header).
 * By default, this function uses the csrf-token meta tag's content value to set the X-CSRF-Token header and logs errors to the error console.
 * @param url the url to send the POST request to.
 * @param bodyData the data to send in the POST request body.
 * @param options (optional) additional `TurboPostOptions` for the turbo post request.
 *
 */
export async function turboPost(url: string, bodyData: URLSearchParams, options?: TurboPostOptions): Promise<void> {
  // TODO: Replace console.error default handler with one that updates the pages Flash?
  const onError = options?.onError || console.error; // log to console by default
  const onResponse = options?.onResponse || (() => {}); // noop
  const csrfToken = options?.csrfToken || metaCsrfToken(); // use meta tag if present

  if (!csrfToken) {
    onError(new Error("csrf-token value cannot be undefined"));
    return;
  }
  try {
    const response = await fetch(url, {
      headers: { "X-CSRF-Token": csrfToken! }, // we've already checked that csrfToken is not undefined
      method: "POST",
      body: bodyData,
    });
    if (response.headers.get("Content-Type") === "text/vnd.turbo-stream.html") {
      // update page with turbo stream updates
      Turbo.renderStreamMessage(await response.text());
    }
    // basic response logging - removed automatically in production by esbuild
    console.log(`turboPost response: ${response.status} ${response.statusText} ${response.url}`);
    if (!response.ok) {
      // we print more details to the console when response isn't ok
      console.dir(response);
    }
    onResponse(response);
  } catch (error) {
    // let caller handle the error
    onError(new Error(`Unexpected error: ${error}`));
  }
}

// @ts-ignore
window.turboPost = turboPost;
