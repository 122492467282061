import { turboPost } from "src/turbo";
import { Controller } from "stimulus";
import { SPINNER } from "../icons";

/**
 * TurboOnce sends a Turbo POST to its `url` value, showing a spinner
 * if a `spinner` target is present.
 * 
 * This is useful for cases where you'd like to issue some Turbo stream
 * conditionally from inside a template.
 */
export class TurboOnce extends Controller {
  static targets = ["spinner"];
  static values = { url: String };

  spinnerTarget: HTMLDivElement;
  urlValue: string;

  async connect() {
    console.log("TurboOnce to:", this.urlValue);
    if (this.spinnerTarget) {
      this.spinnerTarget.innerHTML = SPINNER;
    }

    // TODO: Populate URLSearchParams via stimulus params or values?
    // Not needed for current use case but feel free to do this if needed!
    await turboPost(this.urlValue, new URLSearchParams());
  }
}
