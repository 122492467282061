import { Controller } from "@hotwired/stimulus";

/**
 * DynamicAnchors connects anchor tags to other parts of the page on connect.
 *
 * To register an element as able to be linked to, give it an `id` and the `data-dynamic-anchors-target="section"` attribute, e.g.
 *
 * <div id="header" data-dynamic-anchors-target="section">...</div>
 *
 * To render an anchor tag that will automatically have its `href` set to the next anchor on a page,
 * give it the `data-dynamic-anchors-target="anchor"` and a `data-anchor-href-after` attribute with the value of whatever part of the page immediately precedes what it should link to.
 * For example, if I have both "header" and "banner" sections and I want to link to whatever follows "header",
 * in this case "banner", I would write:
 *
 * <a data-dynamic-anchors-target="anchor" data-anchor-href-after="header">...</a>
 */
export class DynamicAnchors extends Controller {
  static targets = ["section", "anchor"];

  sectionTargets: HTMLDivElement[];
  anchorTargets: HTMLAnchorElement[];

  connect() {
    // Build list of anchors
    console.log("DynamicAnchors connected", this.sectionTargets, this.anchorTargets);
    const sections: string[] = this.sectionTargets.map((el) => el.id);

    // Set next anchor based on current sections.
    // If an element's current anchor isn't in `sections` and thus isn't on-page,
    // its next anchor is the first one we know about.
    const defaultAnchor = sections.length > 0 ? sections[0] : "";

    this.anchorTargets.forEach((el) => {
      if (el.dataset.anchorHrefAfter) {
        // set data-turbo="false" on all anchors that have data-anchor-href-after
        // otherwise turbo will try to load the anchor href as a page
        el.dataset.turbo = "false";
        const after = el.dataset.anchorHrefAfter;
        const idx = sections.indexOf(after);
        if (idx < 0 || idx + 1 >= sections.length) {
          if (defaultAnchor === after) {
            // Uh oh! We likely only have one anchor on the page; link to nothing.
            el.href = "#";
          } else {
            el.href = "#" + defaultAnchor;
          }
        } else {
          el.href = "#" + sections[idx + 1];
        }
        console.log(`DynamicAnchors: set data-anchor-href-after="${after}" href to "${el.href}"`);
      }
    });
  }
}
