import Glide from '@glidejs/glide';
import * as Turbo from "@hotwired/turbo";
import confetti from "canvas-confetti";
import mixpanel from "mixpanel-browser";
import { Application } from "stimulus";
import PlacesAutocomplete from "stimulus-places-autocomplete";

import { AdminInput } from "./controllers/adminInput";
import { AnalyticsPageViewsController } from "./controllers/analytics/pageViews";
import { AnalyticsProfileController } from "./controllers/analytics/profile";
import { Autocomplete } from "./controllers/autocomplete";
import { AutosaveText } from "./controllers/autosaveText";
import { Clipboard } from "./controllers/clipboard";
import { Confetti } from "./controllers/confetti";
import { Dropdown } from "./controllers/dropdown/dropdown";
import { DynamicAnchors } from "./controllers/employee/benefit/dynamicAnchors";
import { RankedPrefsController } from "./controllers/employee/rankedPrefs";
import { FetchData } from "./controllers/fetchData";
import { FieldValidator } from "./controllers/fieldValidator";
import { GuideStepController } from "./controllers/guideStep";
import { Lightbox } from './controllers/lightbox';
import { PlacesAutoclear } from "./controllers/places-autoclear";
import { PreApprovalValidator } from "./controllers/preApprovalValidator";
import { VehicleProfileController } from "./controllers/profile";
import { ReadMore } from "./controllers/readMore";
import { Recipients } from "./controllers/recipients";
import { FileInput } from "./controllers/reimbursements/fileInput";
import { SetLocaleController } from './controllers/setLocale';
import { SortableController } from "./controllers/sortable";
import { Tooltip } from "./controllers/tooltip";
import { TurboOnce } from "./controllers/turboOnce";
import { Updater } from "./controllers/updater";
import { UserMgmtChange } from "./controllers/userMgmt";
import { WaitlistButton } from "./controllers/waitlistButton";
import { liveSocket } from "./live";

// register the Stimulus Controllers
const application = Application.start();
application.register("vehicle-profile", VehicleProfileController);
application.register("field-validator", FieldValidator);
application.register("admin-input", AdminInput);
application.register("autosave-text", AutosaveText);
application.register("pre-approval-validator", PreApprovalValidator);
application.register("file-input", FileInput);
application.register("sortable", SortableController);
application.register("ranked-prefs", RankedPrefsController);
application.register("waitlist-button", WaitlistButton);
application.register("recipients", Recipients);
application.register("places-autocomplete", PlacesAutocomplete);
application.register("places-autoclear", PlacesAutoclear);
application.register("autocomplete", Autocomplete);
application.register("analytics-views", AnalyticsPageViewsController);
application.register("analytics-profile", AnalyticsProfileController);
application.register("tooltip", Tooltip);
application.register("fetch", FetchData);
application.register("user-mgmt-change", UserMgmtChange);
application.register("turbo-once", TurboOnce);
application.register("dynamic-anchors", DynamicAnchors);
application.register("dropdown", Dropdown);
application.register("clipboard", Clipboard);
application.register("updater", Updater);
application.register("guide-step", GuideStepController);
application.register("confetti", Confetti);
application.register("read-more", ReadMore);
application.register("lightbox", Lightbox);
application.register("set-locale", SetLocaleController);

// add Turbo to the browser window
// TODO - do we want to set a turbo-root? see: https://turbo.hotwired.dev/handbook/drive#setting-a-root-location
(window as any).Turbo = Turbo;

// add Mixpanel to the browser window
// init is called in head.gohtml
(window as any).mixpanel = mixpanel;

// add GoLive support to the browser window
(window as any).liveSocket = liveSocket;

// Initial Glide
(window as any).Glide = Glide;

(window as any).confetti = confetti;

// add web components to the browser window
export * from "./components/hBar";

