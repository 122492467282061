import { Controller } from "@hotwired/stimulus";

/**
 * Dropdown controller to handle dropdown menus (and eventually form select inputs).
 *
 * You must define the following targets:
 *  - button: the button that toggles the dropdown
 *  - buttonLabel: the element that contains the button label
 *  - buttonExpandedIcon: the element that contains the icon when the dropdown is expanded
 *  - buttonCollapsedIcon: the element that contains the icon when the dropdown is collapsed
 *  - menu: the element that contains the dropdown menu
 *  - menuItem: the elements that are the menu items
 *
 * HTML looks something like this (styling removed for brevity):
 * <div data-controller="dropdown">
 *   <button data-dropdown-target="button" data-action="click->dropdown#toggle:stop click@window->dropdown#hide">
 *     <span data-dropdown-target="buttonLabel">Dropdown</span>
 *     <span data-dropdown-target="buttonExpandedIcon" class="hidden">...</span>
 *     <span data-dropdown-target="buttonCollapsedIcon">...</span>
 *  </button>
 *  <div data-dropdown-target="menu" class="hidden">
 *    ...
 *    <li data-dropdown-target="menuItem" data-action="click->dropdown#select">...</li>
 *    ...
 *  </div>
 * </div>
 *
 * This publishes the following events:
 * - dropdown-expanded: when the dropdown is expanded
 * - dropdown-collapsed: when the dropdown is collapsed
 * - dropdown-selection: when a menu item is selected
 * Each event contains the id of the dropdown element in the detail and the
 * dropdown-selection event also contains the value (index) of the selected menu item.
 */
export class Dropdown extends Controller {
  static targets = ["button", "buttonLabel", "buttonExpandedIcon", "buttonCollapsedIcon", "menu", "menuItem"];
  static values = { open: Boolean };

  declare readonly menuTarget: HTMLDivElement;
  declare readonly menuItemTargets: HTMLButtonElement[];
  declare readonly buttonTarget: HTMLButtonElement;
  declare readonly buttonLabelTarget: HTMLSpanElement;
  declare readonly buttonExpandedIconTarget: HTMLSpanElement;
  declare readonly buttonCollapsedIconTarget: HTMLSpanElement;

  declare openValue: boolean;

  connect() {
    console.log(
      "Dropdown controller connected",
      this.buttonTarget,
      this.buttonLabelTarget,
      this.buttonCollapsedIconTarget,
      this.buttonExpandedIconTarget,
      this.menuTarget,
      this.menuItemTargets
    );

    // automatically listen for keys so we can close the dropdown on Escape
    this.buttonTarget.addEventListener("keydown", this._onMenuButtonKeydown);
    this.buttonTarget.setAttribute("aria-haspopup", "true");
  }

  disconnect() {
    // disconnect our keydown listener
    this.buttonTarget.removeEventListener("keydown", this._onMenuButtonKeydown);
    this.buttonTarget.removeAttribute("aria-haspopup");
  }

  toggle(event: Event) {
    this.openValue = !this.openValue;
    event.stopPropagation(); // prevent click event from bubbling up to window, which causes another toggle...
  }

  show() {
    this.openValue = true;
  }

  hide() {
    this.openValue = false;
  }

  select(event: Event) {
    const option = event.currentTarget as HTMLLIElement;
    const value = option.value; // index of selected option
    this.buttonLabelTarget.textContent = option.textContent;
    this.hide();
    console.log("Dropdown selection", value);
    this.element.dispatchEvent(
      new CustomEvent("dropdown-selection", {
        detail: { id: this.element.id, value },
        bubbles: true,
      })
    );
  }

  openValueChanged(newValue: boolean, oldValue: boolean) {
    if (newValue === oldValue) {
      return;
    }
    if (this.openValue) {
      this._show();
    } else {
      this._hide();
    }
  }

  _show() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.buttonCollapsedIconTarget.classList.add("hidden");
    this.buttonExpandedIconTarget.classList.remove("hidden");
    this.menuTarget.classList.remove("hidden");
    this.element.dispatchEvent(
      new CustomEvent("dropdown-expanded", {
        detail: { id: this.element.id },
        bubbles: true,
      })
    );
  }

  _hide() {
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.buttonCollapsedIconTarget.classList.remove("hidden");
    this.buttonExpandedIconTarget.classList.add("hidden");
    this.menuTarget.classList.add("hidden");
    this.element.dispatchEvent(
      new CustomEvent("dropdown-collapsed", {
        detail: { id: this.element.id },
        bubbles: true,
      })
    );
  }

  _onMenuButtonKeydown = (event: KeyboardEvent) => {
    switch (event.key) {
      case "Escape":
        this.hide();
    }
  };
}
