import { Controller } from "stimulus";
import { useDebounce } from "../debouce";
import { SPINNER } from "../icons";
import { turboPost } from "../turbo";

// TODO: rename AutosaveText to AutosaveInput?
export class AutosaveText extends Controller {
  static debounces = ["update"];
  static targets = ["input", "status"];
  static values = { url: String, clearStatusTimeout: Number };

  inputTarget: HTMLInputElement | HTMLSelectElement;
  statusTarget: HTMLDivElement;
  urlValue: string;
  clearStatusTimeoutValue: number;

  async connect() {
    const debounceMilli = 500;
    useDebounce(this, { wait: debounceMilli, name: "update" });
    this.inputTarget.classList.add("invalid:focus:ring-red-700", "invalid:focus:border-red-700");
    console.log("AutosaveText connected", this.urlValue, this.clearStatusTimeoutValue, this.inputTarget, this.statusTarget)
  }

  async clearStatus() {
    this.statusTarget.innerHTML = "";
  }

  async update() {
    const statusTarget = this.statusTarget;

    if (!this.inputTarget.validity.valid) {
      console.log(`ignoring text field ${this.inputTarget.name}, not valid`);
      return;
    }
    console.log(`updated text field ${this.inputTarget.name}, POSTing to ${this.urlValue}`);

    statusTarget.innerHTML = SPINNER;

    // build post data
    const bodyData = new URLSearchParams({
      [this.inputTarget.name]: this.inputTarget.value,
    });

    // post to server
    await turboPost(this.urlValue, bodyData, {
      onResponse: (response) => {
        if (response.ok) {
          statusTarget.innerHTML = "✅";
          if(this.clearStatusTimeoutValue > 0) {
            setTimeout(() => {
              statusTarget.innerHTML = "";
            }, this.clearStatusTimeoutValue);
          }
        } else {
          statusTarget.innerHTML = "⛔️";
        }
      },
      onError() {
        statusTarget.innerHTML = "⛔️";
      },
    });
  }
}
