import { Controller } from "stimulus";
import { turboPost } from "../../turbo";

export class VehicleProfileController extends Controller {
  static targets = ["vehicleCount"];
  static values = { url: String };

  vehicleCountTarget: HTMLSelectElement;
  prevVehicleCount: string;
  urlValue: string;

  connect() {
    // save initial vehicle count
    this.prevVehicleCount = this.vehicleCountTarget.value;
  }

  async update() {
    // get new vehicle count
    const newCount = this.vehicleCountTarget.value;

    const data = new URLSearchParams({
      NewCount: newCount,
      PriorCount: this.prevVehicleCount || "0",
    });

    await turboPost(this.urlValue, data, {
      onResponse: (res) => {
        if (res.ok) {
          // update the vehicle count
          this.prevVehicleCount = newCount;
        }
      },
    });
  }
}
