import { Controller } from "stimulus";

export class Lightbox extends Controller {
  static targets = ["container", "image", "name", "description", "avatar", "noavatar", "previous", "next"];
  static values = {imagesJson: String };

  containerTarget: HTMLDivElement;
  imageTarget: HTMLImageElement;
  nameTarget: HTMLHeadingElement;
  descriptionTarget: HTMLSpanElement;
  avatarTarget: HTMLImageElement;
  noavatarTarget: HTMLDivElement;
  previousTarget: HTMLButtonElement;
  nextTarget: HTMLButtonElement;

  imagesJsonValue: string;

  private images: {
    Path: string;
    Avatar: string;
    Name: string;
    Description: string;
  }[];
  private currentIndex = 0;

  connect() {
    console.log("lightbox", this.containerTarget);

    this.images = JSON.parse(this.imagesJsonValue);
  }

  hide() {
    this.containerTarget.classList.add("hidden");
  }

  show(event: any) {
    const { idx } = event.params;
    this.updateImage(idx);
    this.containerTarget.classList.remove("hidden");
  }

  previous() {
    let newIdx = this.currentIndex - 1;
    if (newIdx < 0) {
      newIdx = this.images.length - 1;
    }
    this.updateImage(newIdx)
  }

  next() {
    let newIdx = this.currentIndex + 1;
    if (newIdx >= this.images.length) {
      newIdx = 0;
    }
    this.updateImage(newIdx)
  }

  private updateImage(idx: number) {
    this.currentIndex = idx;
    const img = this.images[this.currentIndex];
    if (!img) {
      return;
    }
    this.imageTarget.src = img.Path;
    // This is silly, but otherwise the image may not update, I think due to browser caching.
    this.imageTarget.hidden = true;
    setTimeout(() => {
      this.imageTarget.hidden = false;
    }, 0);
    this.nameTarget.innerText = img.Name;
    this.descriptionTarget.innerText = img.Description;
    if (img.Avatar != "") {
      this.avatarTarget.classList.add("block");
      this.avatarTarget.classList.remove("hidden");
      this.avatarTarget.src = img.Avatar;
      this.noavatarTarget.classList.add("hidden");
      this.noavatarTarget.classList.remove("block");
    } else {
      this.avatarTarget.classList.add("hidden");
      this.avatarTarget.classList.remove("block");
      this.noavatarTarget.classList.add("block");
      this.noavatarTarget.classList.remove("hidden");
    }
  }
}
